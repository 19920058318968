import { makeObservable, observable, action } from "mobx";
import * as goalService from "../services/goalService";

export class GoalsStore {
  goalsLoading = false;
  sprintsLoading = false;
  goalDialogOpen = false;
  goalDialogReadOnly = false;
  userGoals = null;
  activeGoal = null;
  activeMilestone = null;
  deletedGoalId = null;
  goalsError = null;
  goalDraftData = {};
  developmentSprints = [];
  archivedSprints = [];
  activeSprint = null;
  activeCheckpoint = null;
  activeReview = null;
  draftSprint = {};
  companyObjectives = null;
  currentUserId = null;
  sprintReviewAnswers = [];
  sprintEditMode = false;
  tempSelectedMilestone = null;
  connectedGoals = [];
  isConnectedGoals = false;
  activeSkill = null;
  connectedGoalsOpen = false;
  answersCount = 0;
  coachEvaluation = null;
  selfEvaluation = null;
  peerEvaluation = null;
  draftEvaluationAnswers = null;
  userEvaluations = null;
  coacheeEvaluations = null;
  planTabIndex = 0;
  selectedDraftSprintData = null;
  draftSprintDialogOpen = false;
  skillTypes = [];
  scheduleStatus = null;
  selectedSkills = [];
  searchedSkills = [];
  isFromSprint = false;
  goalTemplates = [];
  goalTemplateMode = false;
  isTemplateGoals = false;
  sprintCreated = false;
  suggestedCoaches = [];

  setCurrentUserId = (userId) => {
    this.currentUserId = userId;
  };

  setAnswersCount = (count) => {
    this.answersCount = count;
  };

  getUserGoals = async () => {
    this.goalsLoading = true;
    let data = await goalService.getUserGoals();
    if (data.error) {
      this.goalsError = data.error;
    } else {
      this.userGoals = data.userGoals;
      if (this.activeGoal && this.activeGoal.goalType !== "goal_template") {
        this.activeGoal = data.userGoals.find(
          (goal) => goal.id === this.activeGoal.id
        );
      }
    }
    this.goalsLoading = false;
  };

  getUserSprints = async (userId, month) => {
    if (!userId) {
      userId = this.currentUserId;
    }
    this.sprintsLoading = true;
    let data = await goalService.getUserSprints(userId, month);
    this.developmentSprints = data.userDevelopmentSprints;
    data.userDevelopmentSprints.map((sprint) => {
      if (sprint.status === "ACTIVE") {
        this.activeSprint = sprint;
      }
    });
    this.sprintsLoading = false;
  };

  getArchivedSprints = async (userId) => {
    this.sprintsLoading = true;
    if (userId) {
      let data = await goalService.getArchivedSprints(userId);
      this.archivedSprints = data.archivedDevelopmentSprints;
    }
    this.sprintsLoading = false;
  };

  updateData = async (userId) => {
    await this.getUserGoals();
    await this.getUserSprints(userId);
  };

  /* Goal and milestone creation / edit */

  createGoal = async (data, userId, fromTemplate = false) => {
    const result = await goalService.createGoal(data, userId);
    await this.updateData(userId);
    if (!fromTemplate) {
      await this.setActiveGoalById(result.createGoal.goal.id);
      await this.setGoalDialogState(true);
    }
    return result.createGoal;
  };

  updateGoal = async (data, goalId, userId) => {
    const result = await goalService.updateGoal(data, goalId, userId);
    this.updateData(userId);
    return result.updateGoal;
  };

  createMilestone = async (data, goalId, userId) => {
    await goalService.createMilestone(data, goalId, userId);
    await this.updateData(userId);
  };

  editMilestone = async (data, milestoneId, userId) => {
    await goalService.editMilestone(data, milestoneId, userId);
    this.updateData(userId);
  };

  createMilestoneComment = async (comment, milestoneId, userId) => {
    await goalService.createMilestoneComment(comment, milestoneId, userId);
    await this.updateData(userId);
  };

  deleteGoal = async (goalId, userId) => {
    await goalService.deleteGoal(goalId, userId);
    this.setActiveGoal(null);
    this.setGoalDialogState(false);
    this.deletedGoalId = goalId;
    this.updateData(userId);
  };

  updateGoalDraftData = (data) => {
    this.goalDraftData = data;
  };

  setActiveGoal = (goal) => {
    this.activeGoal = goal;
  };

  setTempSelectedMilestone = (milestone) => {
    this.tempSelectedMilestone = milestone;
  };

  setSprintReviewAnswers = async (answers) => {
    this.sprintReviewAnswers = [];
    this.sprintReviewAnswers = answers;
    await this.createSprintReviewQuestionsAnswers(answers);
  };

  setActiveGoalById = (goalId) => {
    this.activeGoal = this.userGoals.find((goal) => goal.id === goalId);
  };

  setActiveCheckpoint = (checkpointId) => {
    let activeCheckpoints = this.activeSprint.checkpoints.filter(
      (checkpoint) => checkpoint.id === checkpointId
    );
    this.activeCheckpoint = activeCheckpoints[0];
  };

  setActiveCheckpointObject = (checkpoint) => {
    this.activeCheckpoint = checkpoint;
  };

  setActiveReviewObject = (review) => {
    this.activeReview = review;
  };

  setGoalDialogState = (state) => {
    this.goalDialogOpen = state;
  };

  setGoalDialogReadOnlyState = (state) => {
    this.goalDialogReadOnly = state;
  };

  setSprintEditMode = (mode) => {
    this.sprintEditMode = mode;
  };

  setActiveSkill = (skill) => {
    this.activeSkill = skill;
  };

  updateMilestoneStatus = async (milestoneId, userId, state) => {
    await goalService.editMilestone({ state: state }, milestoneId, userId);
    this.updateData(userId);
  };

  updateMilestoneCompletionPercentage = async (
    milestoneId,
    userId,
    percentage
  ) => {
    await goalService.editMilestone(
      { completionPercentage: percentage },
      milestoneId,
      userId
    );
    this.updateData(userId);
  };

  deleteMilestone = async (milestoneId, userId) => {
    await goalService.deleteMilestone(milestoneId, userId);
    this.updateData(userId);
  };

  /* Sprint actions */

  updateDraftSprint = (data) => {
    const existingSprint = { ...this.draftSprint };
    const updatedSprint = Object.assign(existingSprint, data);
    this.draftSprint = updatedSprint;
  };

  updateDraftSprintGoals = (data) => {
    if (this.draftSprint) {
      this.draftSprint.goals = data;
    }
  };

  createSprint = async (data) => {
    let res = await goalService.createSprint(data);

    if (res.errors) {
      throw res.errors.message;
    }
  };

  finishCurrentSprint = async (userId, updateData) => {
    let sprintId = this.activeSprint.id;
    let res = await goalService.updateSprintStatus(sprintId, updateData);
    if (res.errors) {
      throw res.errors.message;
    }
    this.activeSprint = null;
    this.draftSprint = {};
    this.updateData(userId);
  };

  updateCurrentSprint = async (userId, updateData) => {
    let sprintId = this.activeSprint.id;
    let res = await goalService.updateSprintStatus(sprintId, updateData);
    if (res.errors) {
      throw res.errors.message;
    }
    this.updateData(userId);
  };

  createCheckpointQuestionsAnswers = async (data) => {
    let res = await goalService.createCheckpointQuestionsAnswers({
      answers: data,
    });
    if (res.errors) {
      throw res.errors.message;
    }
  };

  createSprintReviewQuestionsAnswers = async (data) => {
    let res = await goalService.createSprintReviewQuestionsAnswers({
      answers: data,
    });
    this.getUserSprints();
    return res;
  };

  getCompanyObjectives = async () => {
    let data = await goalService.getCompanyObjectives();
    this.companyObjectives = data.companyObjectives;
  };

  updateConnedtedGoals = (goals) => {
    this.connectedGoals = goals;
  };

  setIsConnectedGoals = (state) => {
    this.isConnectedGoals = state;
  };

  setConnetedGoalsOpen = (state) => {
    this.connectedGoalsOpen = state;
  };

  getSelfEvaluation = async (sprintId, evaluationId) => {
    let data = await goalService.getSelfEvaluation(sprintId, evaluationId);

    if (data.selfEvaluation) {
      this.selfEvaluation = data.selfEvaluation;
    } else {
      this.selfEvaluation = null;
    }
  };

  getCoachEvaluation = async (evaluationId) => {
    let data = await goalService.getEvaluation(evaluationId);
    if (data.evaluation) {
      this.coachEvaluation =
        data.evaluation.type === "COACH" ? data.evaluation : null;
    }
  };

  getPeerEvaluation = async (evaluationId) => {
    let data = await goalService.getEvaluation(evaluationId);
    if (data.evaluation) {
      this.peerEvaluation =
        data.evaluation.type === "PEER" ? data.evaluation : null;
    }
  };

  createEvaluationAnswers = async (answers, evaluationId) => {
    let res = await goalService.createEvaluationAnswers(answers, evaluationId);
    this.getSelfEvaluation(this.activeSprint.id);
    return res;
  };

  createCoachEvaluationAnswers = async (answers, evaluationId) => {
    let res = await goalService.createEvaluationAnswers(answers, evaluationId);
    return res;
  };

  createPeerEvaluationAnswers = async (answers, evaluationId) => {
    let res = await goalService.createEvaluationAnswers(answers, evaluationId);
    return res;
  };

  setDraftEvaluationAnswers = (answers) => {
    this.draftEvaluationAnswers = answers;
  };

  getUserEvaluations = async (userId) => {
    let data = await goalService.getUserEvaluations(userId);
    this.userEvaluations = data.userEvaluations;
  };

  getCoacheeEvaluations = async (userId) => {
    let data = await goalService.getUserEvaluations(userId);

    this.coacheeEvaluations = data.userEvaluations;
  };

  setPlanTabIndex = (index) => {
    this.planTabIndex = index;
  };

  updateEvaluationSeen = async (evaluationId) => {
    await goalService.updateEvaluationSeen(evaluationId);
  };
  setSelectedDraftSprintData = (data) => {
    this.selectedDraftSprintData = data;
  };

  setDraftSprintDialogOpen = (state) => {
    this.draftSprintDialogOpen = state;
  };

  setSkillTypes = (skillTypes) => {
    this.skillTypes = skillTypes;
  };

  setScheduleStatus = (status) => {
    this.scheduleStatus = status;
  };

  setSelectedSkills = (selectedSkills) => {
    this.selectedSkills = selectedSkills;
  };
  setSearchedSkills = (skills) => {
    this.searchedSkills = skills;
  };

  setIsFromSprint = (state) => {
    this.isFromSprint = state;
  };
  setGoalTemplates = (goals) => {
    this.goalTemplates = goals;
    if (this.draftSprint && this.draftSprint.goals) {
      this.draftSprint.goals = goals;
    }
  };

  setSuggestedCoaches = (coaches) => {
    this.suggestedCoaches = coaches;
  };

  updateGoalTemplates = (goal) => {
    this.activeGoal = goal;
    const updatedGoals = this.goalTemplates.map((goalTemplate) =>
      goalTemplate.id === goal.id ? goal : goalTemplate
    );

    if (this.draftSprint && this.draftSprint.goals) {
      const updatedGoals = this.draftSprint.goals.map((goalTemplate) =>
        goalTemplate.id === goal.id ? goal : goalTemplate
      );
      this.draftSprint.goals = updatedGoals;
    }

    this.goalTemplates = updatedGoals;
  };

  setGoalTemplateMode = (mode) => {
    this.goalTemplateMode = mode;
  };

  setIsTemplateGoals = (state) => {
    this.isTemplateGoals = state;
  };

  resetDraftSprint = () => {
    this.draftSprint = {};
  };

  setSprintCreated = (state) => {
    this.sprintCreated = state;
  };

  /* Clear store on logout */

  resetStore = () => {
    this.developmentSprints = [];
    this.userGoals = null;
    this.currentUserId = null;
    this.draftSprint = {};
    this.activeMilestone = null;
    this.activeSprint = null;
    this.activeGoal = null;
    this.activeCheckpoint = null;
    this.answersCount = 0;
    this.planTabIndex = 0;
    this.draftSprintData = false;
    this.selectedDraftSprintData = false;
    this.skillsTypes = [];
    this.scheduleStatus = null;
    this.selectedSkills = [];
    this.goalTeamplates = [];
    this.goalTemplateMode = false;
  };

  constructor() {
    makeObservable(this, {
      deleteGoal: action,
      getUserGoals: action,
      createGoal: action,
      updateGoal: action,
      updateData: action,
      createMilestone: action,
      editMilestone: action,
      createSprint: action,
      getUserSprints: action,
      setActiveGoal: action,
      setActiveGoalById: action,
      setGoalDialogState: action,
      setGoalDialogReadOnlyState: action,
      setActiveCheckpoint: action,
      setActiveCheckpointObject: action,
      setActiveReviewObject: action,
      updateMilestoneStatus: action,
      updateMilestoneCompletionPercentage: action,
      updateDraftSprint: action,
      updateDraftSprintGoals: action,
      goalDraftData: observable,
      updateGoalDraftData: action,
      resetStore: action,
      deleteMilestone: action,
      updateConnedtedGoals: action,
      setConnetedGoalsOpen: action,
      getSelfEvaluation: action,
      setPlanTabIndex: action,
      updateEvaluationSeen: action,
      setSprintCreated: action,
      setSuggestedCoaches: action,
      suggestedCoaches: observable,
      draftSprint: observable,
      userGoals: observable,
      goalsLoading: observable,
      goalDialogOpen: observable,
      activeGoal: observable,
      deletedGoalId: observable,
      activeMilestone: observable,
      activeSprint: observable,
      activeReview: observable,
      activeCheckpoint: observable,
      goalsError: observable,
      companyObjectives: observable,
      developmentSprints: observable,
      archivedSprints: observable,
      sprintReviewAnswers: observable,
      sprintEditMode: observable,
      tempSelectedMilestone: observable,
      activeSkill: observable,
      connectedGoals: observable,
      connectedGoalsOpen: observable,
      isConnectedGoals: observable,
      answersCount: observable,
      coachEvaluation: observable,
      draftEvaluationAnswers: observable,
      planTabIndex: observable,
      coacheeEvaluations: observable,
      selectedDraftSprintData: observable,
      draftSprintDialogOpen: observable,
      skillTypes: observable,
      scheduleStatus: observable,
      selectedSkills: observable,
      searchedSkills: observable,
      isFromSprint: observable,
      goalTemplates: observable,
      goalTemplateMode: observable,
      isTemplateGoals: observable,
      sprintCreated: observable,
      setIsConnectedGoals: action,
      getArchivedSprints: action,
      getCompanyObjectives: action,
      currentUserId: observable,
      selfEvaluation: observable,
      peerEvaluation: observable,
      userEvaluations: observable,
      setCurrentUserId: action,
      createSprintReviewQuestionsAnswers: action,
      setSprintReviewAnswers: action,
      setSprintEditMode: action,
      updateCurrentSprint: action,
      setTempSelectedMilestone: action,
      setActiveSkill: action,
      setAnswersCount: action,
      createEvaluationAnswers: action,
      createPeerEvaluationAnswers: action,
      getCoachEvaluation: action,
      getPeerEvaluation: action,
      setDraftEvaluationAnswers: action,
      createCoachEvaluationAnswers: action,
      getUserEvaluations: action,
      getCoacheeEvaluations: action,
      setSelectedDraftSprintData: action,
      setDraftSprintDialogOpen: action,
      setSkillTypes: action,
      setScheduleStatus: action,
      setSelectedSkills: action,
      setSearchedSkills: action,
      setIsFromSprint: action,
      setGoalTemplates: action,
      updateGoalTemplates: action,
      setGoalTemplateMode: action,
      setIsTemplateGoals: action,
      resetDraftSprint: action,
    });
  }
}
