import React from "react";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, errorMessage: null, errorInfo: null };
  }

  static getDerivedStateFromError(error) {
    // Update state to display the fallback UI
    return { hasError: true, errorMessage: error.message };
  }

  componentDidCatch(error, errorInfo) {
    // Log error details to the console
    console.error("Error captured in ErrorBoundary:", error);
    console.error("Error details:", errorInfo);

    // Optionally send error details to an external logging service
    if (this.props.onError) {
      this.props.onError(error, errorInfo);
    }
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="text-center">
          <h2 className="text-2xl font-bold">Something went wrong.</h2>
          <p className="text-lg mt-4">
            Encountered an error. Please reload or try again later.
          </p>
          {process.env.NODE_ENV === "development" && (
            <details className="mt-4 whitespace-pre-wrap">
              <summary className="cursor-pointer">Error Details</summary>
              <p className="text-red-500">{this.state.errorMessage}</p>
            </details>
          )}
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
