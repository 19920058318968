import React, { useState, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowCircleLeft,
  faClipboard,
  faCommentDots,
  faPaperPlane,
} from "@fortawesome/free-solid-svg-icons";
import { NavLink, useHistory } from "react-router-dom";
import * as goalService from "../../services/goalService";
import { StoresContext } from "../../store/stores";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import moment from "moment";

function SelfReviewEdit() {
  const { t } = useTranslation();
  const history = useHistory();
  const params = useParams();
  const { goalsStore, userStore } = useContext(StoresContext);
  const [evaluationData, setEvaluationData] = useState([]);
  const getReviewsData = goalsStore.selfEvaluation || [];
  const { sprintId, evaluationId } = params;

  const currentLang =
    userStore.currentUser && userStore.currentUser.userSettings.lang;

  useEffect(() => {
    const fetchSelfEvaluation = async () => {
      const response = await goalService.getSelfEvaluation(
        sprintId,
        evaluationId
      );
      setEvaluationData(response.selfEvaluation);
    };
    if (evaluationId && sprintId) {
      fetchSelfEvaluation();
    }
  }, [sprintId, evaluationId]);

  const handleAnswerChange = (index, value) => {
    const newData = { ...evaluationData };
    newData.questions[index].answer = {
      ...newData.questions[index].answer,
      value: {
        ...newData.questions[index]?.answer?.value,
        text: value,
      },
    };
    setEvaluationData(newData);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const payload = {
      evaluationId: evaluationId,
      input: {
        answers: evaluationData?.questions?.map((question, index) => ({
          questionId: question.id,
          id: question.answer.id || undefined,
          value: {
            text: question.answer.value.text || "",
          },
        })),
      },
    };

    goalsStore.createEvaluationAnswers(
      payload.input.answers,
      payload.evaluationId
    );
    goalsStore.setPlanTabIndex(0);
    history.push("/sprint/");
  };

  return (
    <motion.div
      initial={{ y: 50, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      layout
    >
      <div className="d-flex align-items-center">
        <FontAwesomeIcon
          className="mr-2"
          icon={faCommentDots}
          size="xl"
          fixedWidth
        />
        <h4 className="pb-0 mb-0 mr-2">{t("sprint.self_review")}</h4>
        <NavLink to="/sprint/">
          <motion.div
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            animate={{ opacity: 1, transition: { duration: 0.1 } }}
            initial={{ opacity: 0 }}
            className="btn btn-outline-success btn-sm not-rounded shadow-none"
          >
            <FontAwesomeIcon
              icon={faArrowCircleLeft}
              fixedWidth
              className=" mr-1"
            />
            {t("common.go_back")}
          </motion.div>
        </NavLink>
      </div>
      <p className="pt-3 pb-4">
        {moment(evaluationData?.sent).format("DD.MM.YYYY")}
      </p>

      <motion.form
        onSubmit={handleSubmit}
        initial={{ y: 50, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        layout
        className="card pb-5"
      >
        {evaluationData?.questions?.map((question, index) => (
          <div key={question.id} className="px-5 pt-4">
            <h6 className="my-2" style={{ color: "#205072" }}>
              {currentLang && question?.question?.[currentLang]}
            </h6>

            <textarea
              rows="4"
              type="text"
              value={question.answer?.value?.text || ""}
              onChange={(e) => handleAnswerChange(index, e.target.value)}
              className="form-control shadow-lg border-0 rounded-sm"
            />
          </div>
        ))}
        <button
          type="submit"
          className="btn btn-talbit-gradient rounded-0 btn-sm mt-4 py-4 text-capitalize mx-5"
          style={{ borderRadius: "0 !important" }}
        >
          <FontAwesomeIcon icon={faPaperPlane} size="lg" className="mr-2" />
          {t("common.submit")}
        </button>
      </motion.form>
    </motion.div>
  );
}

export default SelfReviewEdit;
